import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import { Grid as MuiGrid, Typography } from '@material-ui/core';

// Components
import styled from 'styled-components';
import Layout from '../components/layout/layout';
import ContactForm from '../components/forms/contactForm';
import Quote from '../components/sections/quote';
import Section from '../components/sections/section';
import Request from '../components/sections/request';
import SEO from '../components/seo';

const ContactSection = styled(Section)`
  padding: 4rem 2rem 2rem 2rem;
  width: 100%;
  ${props => props.theme.breakpoints.up('lg')} {
    padding: 4rem 0 2rem 0;
  }
`;

const Grid = styled(MuiGrid)`
  padding: 0.5rem;
`;

const Link = styled.a`
  text-decoration: none;
  color: ${props => props.theme.palette.text.primary};
`;
const SocialLink = styled(Link)`
  color: white;
  padding: 0.5rem;
  margin: 0 1rem 1rem 0;
  border-radius: 5px;
  font-size: 1.5rem;
  background: ${props => props.theme.palette.primary.main};
`;
const SocialIcon = styled(Img)`
  margin: 0.5rem;
`;

const AddressText = styled(Typography)`
  white-space: pre-line;
`;

// Component
const ContactPage = () => {
  const pageData = useStaticQuery(graphql`
    {
      sanityContactForm {
        heading
        email
      }
      sanityContactPage {
        name
        heading
        image {
          asset {
            fluid(maxWidth: 2000) {
              ...GatsbySanityImageFluid
            }
          }
        }
        socialLinkSection {
          heading
          subheading
          socialLinks {
            name
            url
            iconImage {
              asset {
                fixed(width: 30) {
                  ...GatsbySanityImageFixed
                }
              }
            }
          }
        }
        contactPhone
        contactEmail
        contactAddress
        contactFormToggle
        requestFormToggle
        quoteToggle
      }
    }
  `);

  const page = pageData.sanityContactPage;

  return (
    <Layout
      heroHeading={page.heading == null ? 'Contact Us' : page.heading}
      heroImage={page.image}
    >
      <SEO title="Contact Us" />
      <ContactSection maxWidth="lg">
        <MuiGrid container direction="row">
          {/* <Grid item component={Typography} variant="h2">
            {page.heading == null ? 'Contact Us' : page.heading}
          </Grid> */}
          <MuiGrid item container direction="row">
            <MuiGrid item container direction="row">
              <MuiGrid item component={Typography} variant="h4">
                {page.socialLinkSection.heading == null
                  ? 'Follow Us Online'
                  : page.socialLinkSection.heading}
              </MuiGrid>
              <Grid item container direction="row">
                {page.socialLinkSection.socialLinks &&
                  page.socialLinkSection.socialLinks.map(
                    (socialLink, index) => (
                      <Grid
                        item
                        xs={12}
                        sm={5}
                        md={4}
                        container
                        alignItems="center"
                        component={SocialLink}
                        key={`social-link-${index}`}
                        href={socialLink.url == null ? '/' : socialLink.url}
                        name={socialLink.name}
                      >
                        {socialLink.iconImage && (
                          <SocialIcon
                            fixed={socialLink.iconImage.asset.fixed}
                          />
                        )}
                        {socialLink.name == null
                          ? 'Social Link'
                          : socialLink.name}
                      </Grid>
                    )
                  )}
              </Grid>
            </MuiGrid>
            {page.contactFormToggle && (
              <MuiGrid item container direction="column">
                <ContactForm
                  heading={pageData.sanityContactForm.heading}
                  email={pageData.sanityContactForm.email}
                />
              </MuiGrid>
            )}
            <Grid item xs={12} sm>
              <Typography variant="subtitle1" gutterBottom align="center">
                <b>Phone Number</b>
                <br />
                <Link
                  href={`tel:${
                    page.contactPhone == null ? '' : page.contactPhone
                  }`}
                >
                  {page.contactPhone == null ? '' : page.contactPhone}
                </Link>
              </Typography>
              {page.contactEmail && (
                <Typography variant="subtitle1" gutterBottom align="center">
                  <b>Email Address</b>
                  <br />
                  <Link href={`mailto:${page.contactEmail} `}>
                    {page.contactEmail}
                  </Link>
                </Typography>
              )}
              {page.contactAddress && (
                <AddressText variant="subtitle1" gutterBottom align="center">
                  <b>Mailing Address</b>
                  <br />
                  <MuiGrid item component="address">
                    {page.contactAddress}
                  </MuiGrid>
                </AddressText>
              )}
            </Grid>
            <MuiGrid item xs={12} sm={6} md={9} container>
              <Grid
                item
                xs={12}
                md={6}
                component={Typography}
                variant="subtitle1"
                gutterBottom
                align="center"
              >
                <b>Office Hours</b>
                <br />
                Sunday: Closed <br />
                Monday: 10:00am - 6:00pm <br />
                Tuesday: 10:00am - 6:00pm <br />
                Wednesday: 10:00am - 6:00pm <br />
                Thursday: 10:00am - 6:00pm <br />
                Friday: 10:00am - 6:00pm <br />
                Saturday: Closed
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                component={Typography}
                variant="subtitle1"
                gutterBottom
                align="center"
              >
                <b>Operating Hours</b>
                <br />
                Sunday: 8:00am - 10:00pm <br />
                Monday: 8:00am - 10:00pm <br />
                Tuesday: 8:00am - 10:00pm <br />
                Wednesday: 8:00am - 10:00pm <br />
                Thursday: 8:00am - 10:00pm <br />
                Friday: 8:00am - 10:00pm <br />
                Saturday: 8:00am - 10:00pm
              </Grid>
            </MuiGrid>
          </MuiGrid>
        </MuiGrid>
      </ContactSection>
      {/* Request Form */}
      {page.requestFormToggle && <Request />}
      {pageData.sanityContactPage.quoteToggle && <Quote />}
    </Layout>
  );
};

export default ContactPage;
