import React from 'react';
import { TextField } from '@material-ui/core';
import { useField } from 'formik';
import styled from 'styled-components';

const StyledTextField = styled(TextField)`
  & fieldset {
    font-size: 1.25rem;
    border: 1px solid ${props => props.theme.palette.primary.main};
  }

  & .MuiInputBase-input {
    padding: 1rem;
  }

  & label {
    font-size: 1.25rem;
    color: ${props => props.theme.palette.primary.main};
  }

  & .MuiFormHelperText-root {
    font-size: 0.75rem;
  }
`;

const Field = ({ InputLabelProps, className, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <StyledTextField
      className={className}
      name={field.name}
      value={field.value}
      helperText={meta.touched && meta.error ? meta.error : ''}
      error={Boolean(meta.touched && meta.error)}
      size="small"
      fullWidth
      margin="normal"
      InputLabelProps={{
        shrink: true,
      }}
      {...props}
    />
  );
};

export default Field;
