import React, { useState } from 'react';
import { Form, Formik, Field } from 'formik';
import {
  Typography,
  Button,
  Grid as MuiGrid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import * as Yup from 'yup';
import styled from 'styled-components';
import TextField from './fields/TextField';
const https = require('https');

const Grid = styled(MuiGrid)`
  padding: 0.5rem;
`;

const ContactSchema = Yup.object().shape({
  name: Yup.string()
    .min(1, 'Too Short!')
    .required('Required'),
  message: Yup.string()
    .min(2, 'Too Short!')
    .max(1000, 'Too Long!')
    .required('Required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
});

const ContactForm = ({ heading, email }) => {
  const [open, setOpen] = useState(false);
  const httpsAgent = new https.Agent({
    rejectUnauthorized: false,
  });

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Formik
      initialValues={{
        name: '',
        email: '',
        message: '',
      }}
      validationSchema={ContactSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        fetch('https://temp.yellowalbatross.ca/submit/5ebd90f228d5cb0001371a96/contact', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(values),
          agent: httpsAgent,
        });
        setOpen(true);
        resetForm({});
        setSubmitting(false);
      }}
    >
      {props => (
        <MuiGrid>
          <Typography variant="h4">{heading}</Typography>
          <Grid item component={Form}>
            <Field
              name="name"
              label="Full Name"
              variant="outlined"
              as={TextField}
            />
            <Field
              name="email"
              label="Email Address"
              variant="outlined"
              as={TextField}
            />
            <Field
              name="message"
              label="Message"
              as={TextField}
              multiline
              variant="outlined"
              rows={5}
            />
            <Button
              disabled={props.isSubmitting}
              type="submit"
              variant="contained"
              color="primary"
              endIcon={<SendIcon />}
            >
              Send
            </Button>
            <Dialog
              open={open}
              keepMounted
              onClose={handleClose}
              aria-labelledby="simple-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="simple-dialog-title">Message Sent!</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Hi, Your message is on its way, we will respond as soon as
                  possible. If you do not hear from us within 48 hours please
                  ensure the email you used was entered correctly.
                  <br />
                  <br />> Dayna's Pet Sitting
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="secondary" autoFocus>
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        </MuiGrid>
      )}
    </Formik>
  );
};

export default ContactForm;
